import React, { createContext, useState } from "react";
import { postApi, putApi } from "./api";
import { useNavigate, useLocation } from "react-router-dom";
import AWS from "aws-sdk";

export const DataContext = createContext();

export default function DataProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  //const location = useLocation();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    status: "",
  });

  // AWS Configuration
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  // S3 instance
  const s3 = new AWS.S3();

  // Global Snackbar
  const handleSnackbarOpen = (message = "Success!", status = "success") => {
    setSnackbarState({
      open: true,
      message: message,
      status: status,
    });
    setTimeout(() => {
      setSnackbarState({
        open: false,
        message: "",
        status: "",
      });
    }, 2500);
  };

  // Upload files to S3
  const uploadFileToS3 = async (file, filePath) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: filePath,
      Body: file,
    };
    return s3.upload(params).promise();
  };

  const login = async (data) => {
    try {
      setLoading(true);
      const response = await postApi("auth/login", data);
      if (response.data.status === "success") {
        setUser(response.data.data);
        handleSnackbarOpen("Login success", "success");
        if (location?.state?.from?.pathname) {
          console.log("Navigating to location");
          navigate(location.state.from.pathname);
        } else {
          navigate("/home");
        }
        setLoading(false);
      } else {
        handleSnackbarOpen(response.data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      handleSnackbarOpen("Login failed", "error");
      setUser(null);
      setLoading(false);
    }
  };

  const googleLogin = async () => {
    //const googleLoginURL = "http://localhost:5555/api/auth/google"; // Adjust this URL as necessary
    const googleLoginURL = `${process.env.REACT_APP_API_URL}/auth/google`;
    window.open(googleLoginURL, "_self");
  };

  const signup = async (
    data,
    profilePicture = null,
    backgroundPicture = null
  ) => {
    try {
      setLoading(true);

      // Create user
      const response = await postApi("auth/register", data);
      if (response.data.status !== "success") {
        handleSnackbarOpen("Signup failed", "error");
        setLoading(false);
        return;
      }

      if (profilePicture || backgroundPicture) {
        // Upload pictures
        const profilePicturePath = `userAssets/${response.data.data.email}${response.data.data._id}${profilePicture.name}`;
        const backgroundPicturePath = `userAssets/${response.data.data.email}${response.data.data._id}${backgroundPicture.name}`;

        const ppUpload = uploadFileToS3(profilePicture, profilePicturePath);
        const bpUpload = uploadFileToS3(
          backgroundPicture,
          backgroundPicturePath
        );

        const [ppResponse, bpResponse] = await Promise.all([
          ppUpload,
          bpUpload,
        ]);

        if (!(ppResponse && bpResponse)) {
          handleSnackbarOpen("Signup failed", "error");
          setLoading(false);
          return;
        }

        // Middleware to parse the data field of the user
        const userData = JSON.parse(response.data.data.data);
        userData.backgroundPicture = bpResponse.Location;

        // Data to be submitted
        const newUserData = {
          ...response.data.data,
          picture: ppResponse.Location,
          data: JSON.stringify(userData),
        };

        // Update user with pictures
        const updateResponse = await putApi(
          `auth/update/${response.data.data._id}`,
          newUserData
        );

        if (updateResponse.data.status === "success") {
          setUser(updateResponse.data.data);
          handleSnackbarOpen("Signup success", "success");
          if (location?.state?.from?.pathname) {
            console.log("Navigating to location");
            navigate(location.state.from.pathname);
          } else {
            navigate("home");
          }
          setLoading(false);
        } else {
          handleSnackbarOpen("Login failed", "error");
          setLoading(false);
        }
      } else {
        setUser(response.data.data);
        handleSnackbarOpen("Signup success", "success");
        navigate("/home");
        setLoading(false);
      }
    } catch (error) {
      handleSnackbarOpen("Signup failed", "error");
      setUser(null);
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      const response = await postApi("auth/logout");
      if (response.data.status === "success") {
        setUser(null);
        handleSnackbarOpen("Logged out", "success");
        navigate("/");
        setLoading(false);
      } else {
        handleSnackbarOpen("Logout failed", "error");
        setLoading(false);
      }
    } catch (error) {
      handleSnackbarOpen("Logout failed", "error");
      setLoading(false);
    }
  };

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        login,
        googleLogin,
        signup,
        logout,
        handleSnackbarOpen,
        snackbarState,
        setSnackbarState,
        loading,
        setLoading,
        userLocation,
        setUserLocation,
        uploadFileToS3,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
