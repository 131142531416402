import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataContext } from "../../../utils/DataContext";
import { Paper, TextField, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import FullScreenLoader from "../../layouts/Main/FullScreenLoader";
import { useTheme } from "@mui/material";
import google from "../../../assets/images/google.png";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import bg from "../../../assets/images/bg.jpg";

export default function Login() {
  const { login, signup, loading, googleLogin, handleSnackbarOpen } =
    useContext(DataContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      given_name: "",
      family_name: "",
      confirmPassword: "",
    },
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onSubmit = (data) => {
    console.log(data);
    activeTab === 0 ? loginUser(data) : signupUser(data);
  };

  const loginUser = async (data) => {
    await login(data);
  };

  const signupUser = async (data) => {
    if (data.password !== data.confirmPassword) {
      handleSnackbarOpen("Passwords do not match", "error");
      return;
    }
    await signup({
      ...data,
      display_name: `${data.given_name} ${data.family_name}`,
      role: "guest",
    });
  };

  const googleLoginUser = async () => {
    await googleLogin();
  };

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <>
          <div className="hidden md:flex flex-col w-full">
            <div
              className="flex flex-col relative w-full h-[100vh] items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Paper
                sx={{
                  position: "relative",
                  paddingX: "50px",
                  paddingBottom: "50px",
                  paddingTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "15px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <MdArrowBackIos
                  className="absolute left-5 top-5 w-[35px] h-[35px] fill-c1 hover:scale-105 cursor-pointer"
                  onClick={() => navigate("/")}
                />

                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Login" />
                  <Tab label="Signup" />
                </Tabs>
                <form
                  className="flex flex-col space-y-8 mt-8"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="flex flex-col space-y-4">
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is required" }}
                      render={({ field }) => (
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label="Email"
                          variant="outlined"
                          type="email"
                          autoComplete="email"
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ""}
                          {...field}
                        />
                      )}
                    />
                    {activeTab === 1 && (
                      <>
                        <Controller
                          name="given_name"
                          control={control}
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <TextField
                              sx={{ minWidth: "300px" }}
                              label="Name"
                              variant="outlined"
                              type="text"
                              autoComplete="first-name"
                              error={!!errors.given_name}
                              helperText={
                                errors.given_name
                                  ? errors.given_name.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="family_name"
                          control={control}
                          rules={{ required: "Last name is required" }}
                          render={({ field }) => (
                            <TextField
                              sx={{ minWidth: "300px" }}
                              label="Last Name"
                              variant="outlined"
                              type="text"
                              autoComplete="last-name"
                              error={!!errors.family_name}
                              helperText={
                                errors.family_name
                                  ? errors.family_name.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </>
                    )}
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "Password is required" }}
                      render={({ field }) => (
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label="Password"
                          variant="outlined"
                          type="password"
                          autoComplete="new-password"
                          error={!!errors.password}
                          helperText={
                            errors.password ? errors.password.message : ""
                          }
                          {...field}
                        />
                      )}
                    />
                    {activeTab === 1 && (
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{ required: "Confirm Password is required" }}
                        render={({ field }) => (
                          <TextField
                            sx={{ minWidth: "300px" }}
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            autoComplete="new-password"
                            error={!!errors.confirmPassword}
                            helperText={
                              errors.confirmPassword
                                ? errors.confirmPassword.message
                                : ""
                            }
                            {...field}
                          />
                        )}
                      />
                    )}
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      fontSize: "19px",
                      fontWeight: "medium",
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    {activeTab === 0 ? "Login" : "Signup"}
                  </Button>
                  {activeTab === 0 && (
                    <div
                      className="mt-6 flex flex-row items-center justify-center bg-transparent rounded-full border-2 border-c1 text-c1 py-2 px-4 cursor-pointer hover:bg-c1 hover:text-white"
                      onClick={() => googleLoginUser()}
                    >
                      <img
                        className="w-[20px] h-[20px]"
                        src={google}
                        alt="google-web"
                      />
                      <p className="text-[20px] ml-6">Continue with Google</p>
                    </div>
                  )}
                </form>
              </Paper>
            </div>
          </div>
          <div className="md:hidden flex flex-col w-full items-center justify-center">
            <div
              className="flex flex-col relative w-full h-[100vh] items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Paper
                sx={{
                  position: "relative",
                  paddingX: "15px",
                  paddingBottom: "45px",
                  paddingTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "15px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <MdArrowBackIos
                  className="z-50 absolute left-5 top-5 w-[35px] h-[35px] fill-c1 hover:scale-105 cursor-pointer"
                  onClick={() => navigate("/")}
                />
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Login" />
                  <Tab label="Signup" />
                </Tabs>
                <form
                  className="flex flex-col space-y-8 mt-8"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="flex flex-col space-y-4">
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is required" }}
                      render={({ field }) => (
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label="Email"
                          variant="outlined"
                          type="email"
                          autoComplete="email"
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ""}
                          {...field}
                        />
                      )}
                    />
                    {activeTab === 1 && (
                      <>
                        <Controller
                          name="given_name"
                          control={control}
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <TextField
                              sx={{ minWidth: "300px" }}
                              label="Name"
                              variant="outlined"
                              type="text"
                              autoComplete="first-name"
                              error={!!errors.given_name}
                              helperText={
                                errors.given_name
                                  ? errors.given_name.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="family_name"
                          control={control}
                          rules={{ required: "Last name is required" }}
                          render={({ field }) => (
                            <TextField
                              sx={{ minWidth: "300px" }}
                              label="Last Name"
                              variant="outlined"
                              type="text"
                              autoComplete="last-name"
                              error={!!errors.family_name}
                              helperText={
                                errors.family_name
                                  ? errors.family_name.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </>
                    )}
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "Password is required" }}
                      render={({ field }) => (
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label="Password"
                          variant="outlined"
                          type="password"
                          autoComplete="new-password"
                          error={!!errors.password}
                          helperText={
                            errors.password ? errors.password.message : ""
                          }
                          {...field}
                        />
                      )}
                    />
                    {activeTab === 1 && (
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{ required: "Confirm Password is required" }}
                        render={({ field }) => (
                          <TextField
                            sx={{ minWidth: "300px" }}
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            autoComplete="new-password"
                            error={!!errors.confirmPassword}
                            helperText={
                              errors.confirmPassword
                                ? errors.confirmPassword.message
                                : ""
                            }
                            {...field}
                          />
                        )}
                      />
                    )}
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      fontSize: "19px",
                      fontWeight: "medium",
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    {activeTab === 0 ? "Login" : "Signup"}
                  </Button>
                  {activeTab === 0 && (
                    <div
                      className="mt-6 flex flex-row items-center justify-center bg-transparent rounded-full border-2 border-c1 text-c1 py-2 px-4 cursor-pointer"
                      onClick={() => googleLoginUser()}
                    >
                      <img
                        className="w-[20px] h-[20px]"
                        src={google}
                        alt="google-web"
                      />
                      <p className="text-[20px] ml-6">Continue with Google</p>
                    </div>
                  )}
                </form>
              </Paper>
            </div>
          </div>
        </>
      )}
    </>
  );
}
