import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../utils/DataContext";
import FullScreenLoader from "../../layouts/Main/FullScreenLoader";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bg from "../../../assets/images/bg.jpg";

export default function LocationNeeded() {
  const navigate = useNavigate();
  const { loading, handleSnackbarOpen } = useContext(DataContext);
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setDeviceType("Android");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("iOS");
    } else {
      setDeviceType("Web");
    }
  }, []);

  const handleRetry = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          navigate("/home");
        },
        (error) => {
          console.error("Error getting the user's location: ", error);
          handleSnackbarOpen(
            "Please follow the steps to enable location services!",
            "error"
          );
          navigate("/location-needed");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      handleSnackbarOpen(
        "Please follow the steps to enable location services!",
        "error"
      );
      navigate("/location-needed");
    }
  };

  const renderInstructions = () => {
    switch (deviceType) {
      case "Android":
        return (
          <div className="text-xl text-white mb-10 font-hel">
            Please enable location services on your Android device:
            <ul className="list-disc list-inside">
              <li>Go to Settings</li>
              <li>Tap on Location</li>
              <li>Turn on Location</li>
              <li>Reload this page</li>
            </ul>
          </div>
        );
      case "iOS":
        return (
          <div className="text-xl text-white mb-10 font-hel">
            Please enable location services on your iOS device:
            <ul className="list-disc list-inside">
              <li>Go to Settings</li>
              <li>Tap on Privacy</li>
              <li>Tap on Location Services</li>
              <li>Turn on Location Services</li>
              <li>Reload this page</li>
            </ul>
          </div>
        );
      case "Web":
        return (
          <div className="text-xl text-white mb-10 font-hel">
            Please enable location services in your browser:
            <ul className="list-disc list-inside">
              <li>Click on the padlock icon next to the URL</li>
              <li>Select "Site settings"</li>
              <li>Find "Location" and set it to "Allow"</li>
              <li>Reload this page</li>
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <>
          <div className="hidden md:flex flex-col w-full">
            <div
              className="flex flex-col relative w-full h-[100vh] items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="text-4xl mb-2 text-white font-hel font-bold">
                Location is needed to use the app!
              </p>
              {renderInstructions()}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRetry}
                sx={{
                  color: "white",
                }}
              >
                Retry Location Access
              </Button>
            </div>
          </div>
          <div className="md:hidden flex flex-col w-full items-center justify-center">
            <div
              className="flex flex-col relative w-full h-[100vh] items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1 px-[5%]"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="text-3xl text-center mb-2 text-white font-hel font-bold">
                Location is needed to use the app!
              </p>
              {renderInstructions()}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRetry}
                sx={{
                  color: "white",
                }}
              >
                Retry Location Access
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
