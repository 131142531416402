import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { DataContext } from "../../../utils/DataContext";
import { postApi } from "../../../utils/api";

const Authenticator = ({ children, allowedRoles }) => {
  const { user, setUser, handleSnackbarOpen } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await postApi("auth/profile");
        if (response.data.status === "success") {
          console.log("Fetched user profile", response.data.data);
          setUser(response.data.data);
          setIsAuthenticated(true);
        } else {
          handleSnackbarOpen("User Not Authenticated", "error");
        }
      } catch (error) {
        handleSnackbarOpen("User Not Authenticated", "error");
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      fetchUserProfile();
    } else {
      setLoading(false);
      setIsAuthenticated(true);
    }
    // eslint-disable-next-line
  }, [user, setUser, handleSnackbarOpen]);

  useEffect(() => {
    if (isAuthenticated && allowedRoles && !allowedRoles.includes("*")) {
      if (!allowedRoles.includes(user?.role)) {
        setIsAuthorized(false);
        handleSnackbarOpen("User Not Authorized", "error");
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, allowedRoles, user]);

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/authenticate" state={{ from: location }} replace />;
  }

  if (!isAuthorized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default Authenticator;
