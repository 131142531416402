import logo from "../../../assets/images/Icon.svg";
import bg from "../../../assets/images/bg.jpg";

export default function FullScreenLoader() {
  return (
    <>
      <div className="hidden md:flex flex-col w-full">
        <div
          className="w-full h-[100vh]"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-[150px] h-[150px] flex items-center justify-center loader">
              <img src={logo} alt="loader-logo" width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col w-full items-center justify-center">
        <div
          className="w-full h-[100vh]"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-[90px] h-[90px] flex items-center justify-center loader">
              <img src={logo} alt="loader-logo" width={90} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
