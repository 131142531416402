import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import letters from "../../../assets/images/letters.png";
import { menuConfig } from "../../config/MenuConfig";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { IoMdMenu } from "react-icons/io";
import { DataContext } from "../../../utils/DataContext";

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

export default function Header() {
  const { user, logout } = useContext(DataContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  const navigate = useNavigate();

  const role = user ? user.role : "guest";
  const menuItems = menuConfig[role] || [];

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY <= 0) {
        // At the top of the page
        setHeaderVisible(true);
      } else if (currentScrollY > lastScrollY) {
        // Scrolling down
        setHeaderVisible(false);
      } else {
        // Scrolling up
        if (lastScrollY - currentScrollY > 0) {
          setHeaderVisible(true);
        }
      }

      lastScrollY = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  return (
    <>
      {/* Web Version */}
      <div className="hidden md:flex flex-col w-full">
        <div
          className={`fixed top-[20px] left-[50%] transform translate-x-[-50%] flex flex-row w-[90%] items-center justify-between py-2 bg-c2 bg-opacity-70 rounded-lg transition-opacity ${
            headerVisible ? "fade-in" : "fade-out"
          }`}
        >
          <div>
            <img className="w-[200px] ml-5" src={letters} alt="letters" />
          </div>
          <div
            className="flex flex-row items-center justify-center bg-c1 mr-2 px-2 rounded-md text-white hover:scale-105 cursor-pointer"
            onClick={handleDrawerOpen}
          >
            <p className="font-sans font-medium text-2xl mr-2 mb-0">MENU</p>
            <IoMdMenu className="w-[30px] h-[30px] m-0" />
          </div>
          <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
            <div
              role="presentation"
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}
              style={{ width: 250 }}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      handleDrawerClose();
                      item.path === "/logout"
                        ? handleLogout()
                        : navigate(item.path);
                    }}
                  >
                    <ListItemIcon>
                      <item.icon className="w-[20px] h-[20px] fill-c1 mr-3" />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
              </List>
            </div>
          </Drawer>
        </div>
      </div>
      {/* Mobile Version */}
      <div className="md:hidden flex flex-col w-full items-center justify-center">
        <div
          className={`fixed top-[20px] left-[50%] transform translate-x-[-50%] flex flex-row w-[90%] items-center justify-between py-2 bg-c2 bg-opacity-70 rounded-lg transition-opacity ${
            headerVisible ? "fade-in" : "fade-out"
          }`}
        >
          <div>
            <img className="w-[150px] ml-3" src={letters} alt="letters" />
          </div>
          <div
            className="flex flex-row items-center justify-center bg-c1 mr-2 px-2 rounded-md text-white hover:scale-105 cursor-pointer"
            onClick={handleDrawerOpen}
          >
            <p className="font-medium text-xl mr-2">Menu</p>
            <IoMdMenu className="w-[30px] h-[30px]" />
          </div>
        </div>

        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <div
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
            style={{ width: 250 }}
          >
            <List>
              {menuItems.map((item, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    handleDrawerClose();
                    item.path === "/logout"
                      ? handleLogout()
                      : navigate(item.path);
                  }}
                >
                  <ListItemIcon>
                    <item.icon className="w-[20px] h-[20px] fill-c1 mr-3" />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    </>
  );
}
