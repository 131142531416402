import React, { useContext } from "react";
import { DataContext } from "../../../utils/DataContext";
import FullScreenLoader from "../../layouts/Main/FullScreenLoader";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bg from "../../../assets/images/bg.jpg";

export default function AllowLocation() {
  const navigate = useNavigate();
  const { user, loading, setUserLocation, handleSnackbarOpen } =
    useContext(DataContext);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          handleSnackbarOpen("Location granted!", "success");
          navigate("/home");
        },
        (error) => {
          console.error("Error getting the user's location: ", error);
          navigate("/location-needed"); // Redirect to location needed
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      navigate("/location-needed"); // Redirect to location needed
    }
  };

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <>
          <div className="hidden md:flex flex-col w-full">
            <div
              className="flex flex-col relative w-full h-[100vh] items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="font-hel text-5xl text-c2 font-black">
                Welcome,
                <span className="text-white"> {user?.given_name}</span>
              </p>
              <p className="text-4xl mb-2 text-white font-hel font-bold mt-8">
                Your Location is needed to use the app!
              </p>
              <p className="text-xl mb-8 text-white">
                Please grant access to your current location before
                continuing...
              </p>
              <Button
                variant="contained"
                color="secondary"
                onClick={getLocation}
                sx={{
                  color: "white",
                }}
              >
                Grant Access
              </Button>
            </div>
          </div>
          <div className="md:hidden flex flex-col w-full items-center justify-center">
            <div
              className="flex flex-col relative w-full h-[100vh] pt-2 bg-gradient-to-bl from-c1 via-c1Dark to-c1 px-[5%]"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="font-hel text-4xl text-c2 font-black">
                Welcome,
                <span className="text-white"> {user?.given_name}</span>
              </p>
              <p className="text-2xl text-center mb-2 text-white font-hel font-bold mt-6">
                Your Location is needed to use the app!
              </p>
              <p className="text-lg mb-8 text-white text-center">
                Please grant access to your current location before
                continuing...
              </p>
              <Button
                variant="contained"
                color="secondary"
                onClick={getLocation}
                sx={{
                  color: "white",
                }}
              >
                Grant Access
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
