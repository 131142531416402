import React from "react";
import Header from "../../layouts/Main/Header";
import Footer from "../../layouts/Main/Footer";
import { Outlet } from "react-router-dom";
import FullScreenDiv from "../../layouts/Main/FullScreenDiv";
import bg from "../../../assets/images/bg.jpg";

export default function Main() {
  return (
    <div className="flex w-full font-hel">
      {/* Versión WEB */}
      <div className="hidden md:flex flex-col w-full">
        <div
          className="flex flex-col w-full relative"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Navbar */}
          <div className="z-30 w-full flex items-center justify-center">
            <Header />
          </div>
          {/* Contenido */}
          <div className="w-full">
            <FullScreenDiv>
              <Outlet />
            </FullScreenDiv>
          </div>
        </div>
        {/* Footer */}
        <div className="w-full">
          <Footer />
        </div>
      </div>
      {/* Versión MÓVIL */}
      <div className="md:hidden flex flex-col w-full">
        <div
          className="flex flex-col w-full relative"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Navbar */}
          <div className="z-30 w-full flex items-center justify-center">
            <Header />
          </div>
          {/* Contenido */}
          <div className="w-full">
            <FullScreenDiv>
              <Outlet />
            </FullScreenDiv>
          </div>
        </div>
        {/* Footer */}
        <div className="w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
}
