import React, { useEffect, useState, useContext } from "react";
import { DataContext } from "../../../utils/DataContext";
import { useNavigate } from "react-router-dom";

const LocationWrapper = ({ children }) => {
  const { userLocation, setUserLocation } = useContext(DataContext);
  const [locationChecked, setLocationChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setLocationChecked(true);
          },
          (error) => {
            console.error("Error getting the user's location: ", error);
            navigate("/location-needed"); // Redirect to location needed
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        navigate("/location-needed"); // Redirect to location needed
      }
    } else {
      setLocationChecked(true);
    }
    // eslint-disable-next-line
  }, []);

  if (!locationChecked) {
    console.log("No location checked");
    return null;
  }

  return <>{children}</>;
};

export default LocationWrapper;
