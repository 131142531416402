import { FaHome } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { BiSolidParty } from "react-icons/bi";
import { MdGroupAdd } from "react-icons/md";

export const menuConfig = {
  guest: [
    {
      name: "Profile",
      path: "/profile",
      icon: FaUser,
    },
    {
      name: "Home",
      path: "/home",
      icon: FaHome,
    },
    {
      name: "Create Party",
      path: "/create-party",
      icon: MdGroupAdd,
    },
    {
      name: "My Parties",
      path: "/parties",
      icon: BiSolidParty,
    },
    {
      name: "Logout",
      path: "/logout",
      icon: TbLogout,
    },
  ],
  admin: [
    {
      name: "Profile",
      path: "/profile",
      icon: FaUser,
    },
    {
      name: "Home",
      path: "/home",
      icon: FaHome,
    },
    {
      name: "Create Party",
      path: "/create-party",
      icon: MdGroupAdd,
    },
    {
      name: "Parties",
      path: "/parties",
      icon: BiSolidParty,
    },
    {
      name: "Logout",
      path: "/logout",
      icon: TbLogout,
    },
  ],
  super: [
    {
      name: "Profile",
      path: "/profile",
      icon: FaUser,
    },
    {
      name: "Home",
      path: "/home",
      icon: FaHome,
    },
    {
      name: "Create Party",
      path: "/create-party",
      icon: MdGroupAdd,
    },
    {
      name: "Parties",
      path: "/parties",
      icon: BiSolidParty,
    },
    {
      name: "Logout",
      path: "/logout",
      icon: TbLogout,
    },
  ],
};
