import React from "react";
import letters from "../../../assets/images/letters.png";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";

export default function Footer() {
  return (
    <>
      <div className="hidden md:flex flex-col w-full">
        <div className="w-full bg-gradient-to-r from-c2Dark via-c2 to-c2Dark pt-[20px] flex flex-col items-center justify-center">
          <img src={letters} alt="letters" className="w-[160px]" />
          <div className="mt-[4px]">
            <p className="font-hel text-md text-white">Keep The Party Alive!</p>
          </div>
          <div className="my-3 flex flex-row items-center justify-center space-x-8">
            <FaInstagram className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaFacebook className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaLinkedin className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaYoutube className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
          </div>
          <span className="w-[90%] border-b-[1px] border-white" />
          <div className="w-full text-center my-3 text-white text-[14px]">
            <p>© 2024 WaveWare Solutions LLC</p>
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col w-full items-center justify-center">
        <div className="w-full bg-gradient-to-r from-c2Dark via-c2 to-c2Dark pt-[20px] flex flex-col items-center justify-center">
          <img src={letters} alt="letters" className="w-[160px]" />
          <div className="mt-[4px]">
            <p className="font-hel text-md text-white">Keep The Party Alive!</p>
          </div>
          <div className="my-3 flex flex-row items-center justify-center space-x-8">
            <FaInstagram className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaFacebook className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaLinkedin className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
            <FaYoutube className="w-[30px] h-[30px] text-white hover:scale-105 cursor-pointer" />
          </div>
          <span className="w-[90%] border-b-[1px] border-white" />
          <div className="w-full text-center my-3 text-white text-[14px]">
            <p>© 2024 Parthium Org</p>
          </div>
        </div>
      </div>
    </>
  );
}
