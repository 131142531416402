import { useEffect, lazy, Suspense } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Context
import DataProvider from "./utils/DataContext";

// Router
import { Route, Routes } from "react-router-dom";

// Material UI
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Main imports
import DataSnackbar from "./components/layouts/Functionalities/DataSnackbar";
import Main from "./components/views/Main/Main";
import FullScreenLoader from "./components/layouts/Main/FullScreenLoader";
import Authenticator from "./components/views/Auth/Authenticator";
import Welcome from "./components/views/Main/Welcome";
import Login from "./components/views/Auth/Login";
import LocationNeeded from "./components/views/Auth/LocationNeeded";
import LocationWrapper from "./components/views/Auth/LocationWrapper";
import AllowLocation from "./components/views/Auth/AllowLocation";
// Lazy Loads
const CreateParty = lazy(() => import("./components/views/Guest/CreateParty"));
const CreatedSuccessfully = lazy(() =>
  import("./components/views/Guest/CreatedSuccessfully")
);
const MainContent = lazy(() => import("./components/views/Main/MainContent"));
const Party = lazy(() => import("./components/views/Guest/Party"));
const MyParties = lazy(() => import("./components/views/Guest/MyParties"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#381569",
      dark: "#170037",
    },
    secondary: {
      main: "#FF8A00",
      dark: "#b86743",
    },
    green: {
      main: "#119617",
      dark: "#478252",
    },
    white: {
      main: "#f8f8f8",
      dark: "#e5e5e5",
    },
  },
});

function App() {
  // animate on scroll
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out-back",
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <DataProvider>
        <Suspense fallback={<FullScreenLoader />}>
          <Routes>
            {/* Routes that do not need authentication */}
            <Route path="/" element={<Welcome />} />
            <Route path="/authenticate" element={<Login />} />
            <Route path="/location-needed" element={<LocationNeeded />} />
            <Route path="/request-location" element={<AllowLocation />} />

            {/* Routes that need Authentication */}
            <Route element={<Main />}>
              {/* Routes that all authenticated users can see */}
              <Route
                path="/home"
                element={
                  <Authenticator allowedRoles={["*"]}>
                    <MainContent />
                  </Authenticator>
                }
              />
              {/* Guest Routes */}
              <Route
                path="/party/:id"
                element={
                  <Authenticator allowedRoles={["guest"]}>
                    <LocationWrapper>
                      <Party />
                    </LocationWrapper>
                  </Authenticator>
                }
              />
              <Route
                path="/create-party"
                element={
                  <Authenticator allowedRoles={["guest"]}>
                    <LocationWrapper>
                      <CreateParty />
                    </LocationWrapper>
                  </Authenticator>
                }
              />
              <Route
                path="/created_successfully/:id"
                element={
                  <Authenticator allowedRoles={["guest"]}>
                    <CreatedSuccessfully />
                  </Authenticator>
                }
              />
              <Route
                path="/parties"
                element={
                  <Authenticator allowedRoles={["guest"]}>
                    <MyParties />
                  </Authenticator>
                }
              />
              {/* Admin Routes */}

              {/* SuperUser Routes */}

              {/* Mixed Routes (More than one role is allowed here) */}
            </Route>
          </Routes>
          <DataSnackbar />
        </Suspense>
      </DataProvider>
    </ThemeProvider>
  );
}

export default App;
