import { useContext } from "react";
import { DataContext } from "../../../utils/DataContext";
import logo from "../../../assets/images/Icon.svg";
import google from "../../../assets/images/google.png";
import letters from "../../../assets/images/letters.png";
import bg from "../../../assets/images/bg.jpg";
import appStore from "../../../assets/images/appStore.png";
import googlePlay from "../../../assets/images/googlePlay.png";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const { googleLogin } = useContext(DataContext);

  const navigate = useNavigate();

  const googleLoginUser = async () => {
    await googleLogin();
  };

  return (
    <>
      <div className="hidden md:flex flex-col w-full">
        <div
          className="flex flex-col relative w-full h-[100vh] space-y-5 items-center justify-center bg-gradient-to-bl from-c1 via-c1Dark to-c1 font-hel"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute w-full top-[35%] left-0 px-[10%]">
            <div className="flex flex-col items-start justify-start">
              <img className="w-[60px]" src={logo} alt="logo" />
              <h1 className="text-c2 mt-6 text-[45px] font-hel font-bold">
                JOIN THE PARTY, ENJOY THE MUSIC
              </h1>
              <div className="flex flex-row items-center justify-center mt-8 font-bold">
                <div
                  className="flex flex-row items-center justify-center bg-transparent rounded-full border-2 border-white text-white py-2 px-4 cursor-pointer hover:scale-105"
                  onClick={() => googleLoginUser()}
                >
                  <img
                    className="w-[20px] h-[20px]"
                    src={google}
                    alt="google-web"
                  />
                  <p className="text-[20px] ml-6">Continue with Google</p>
                </div>
              </div>
              <div>
                <p className="mt-10 text-[16px] font-medium text-white">
                  Don't have an account?
                  <span
                    className="ml-2 underline cursor-pointer text-c2"
                    onClick={() => {
                      navigate("/authenticate");
                    }}
                  >
                    Authenticate manually
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="absolute bottom-[10%] left-[10%] flex flex-row space-x-3">
            <img
              className="w-[170px] cursor-pointer hover:scale-105"
              src={appStore}
              alt="appstore-web"
            />
            <img
              className="w-[170px] cursor-pointer hover:scale-105"
              src={googlePlay}
              alt="googlePlay-web"
            />
          </div>

          <div className="absolute bottom-[10%] right-[10%]">
            <img src={letters} alt="letters-web" />
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col w-full items-center justify-center">
        <div
          className="flex flex-col w-full h-[100vh] bg-gradient-to-bl from-c1 via-c1Dark to-c1 font-hel relative"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full px-[5%] mt-[20%]">
            <div className="flex flex-col items-start justify-start">
              <img className="w-[60px]" src={logo} alt="logo" />
              <h1 className="text-c2 mt-6 text-[45px] font-hel font-bold">
                JOIN THE PARTY, ENJOY THE MUSIC
              </h1>
            </div>
          </div>

          <div className="absolute bottom-[10%] left-0 flex flex-col w-full px-[5%]">
            <div className="flex items-center justify-center w-full font-bold">
              <div
                className="flex flex-row items-center justify-center bg-transparent rounded-full border-2 border-white text-white py-2 px-4 cursor-pointer hover:scale-105"
                onClick={() => googleLoginUser()}
              >
                <img
                  className="w-[20px] h-[20px]"
                  src={google}
                  alt="google-web"
                />
                <p className="text-[20px] ml-6">Continue with Google</p>
              </div>
            </div>

            <div className="flex w-full items-center justify-center">
              <p className="mt-6 text-[16px] font-medium text-white">
                Don't have an account?
                <span
                  className="ml-2 underline cursor-pointer text-c2"
                  onClick={() => {
                    navigate("/authenticate");
                  }}
                >
                  Authenticate manually
                </span>
              </p>
            </div>

            <div className="flex items-center justify-center mt-12 mb-12">
              <img src={letters} alt="letters-web" className="w-[200px]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
