import axios from "axios";
import { API_URL } from "./config";

export const getApi = async (url, options = {}) => {
  try {
    const config = {
      ...options,
      withCredentials: true,
    };
    const res = await axios.get(`${API_URL}/${url}`, config);
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postApi = async (url, body = null, options = {}) => {
  try {
    const config = {
      ...options,
      withCredentials: true,
    };
    const res = await axios.post(`${API_URL}/${url}`, body, config);
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const putApi = async (url, body = null, options = {}) => {
  try {
    const config = {
      ...options,
      withCredentials: true,
    };
    const res = await axios.put(`${API_URL}/${url}`, body, config);
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteApi = async (url, options = {}) => {
  try {
    const config = {
      ...options,
      withCredentials: true,
    };
    const res = await axios.delete(`${API_URL}/${url}`, config);
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};
