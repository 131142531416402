import React from "react";

export default function FullScreenDiv({ children }) {
  return (
    <>
      {/* Versión Web */}
      <div
        style={{ minHeight: "100vh" }}
        className="hidden md:flex flex-col w-full px-[10%]"
      >
        {children}
      </div>
      {/* Versión Móvil */}
      <div
        style={{ minHeight: "100vh" }}
        className="md:hidden flex flex-col w-full px-[5%]"
      >
        {children}
      </div>
    </>
  );
}
